import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Toolbar, Typography } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(theme => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const CustomDialog = props => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      TransitionComponent={Transition}
      aria-labelledby='responsive-dialog-title'
      {...props}
    >
      <Toolbar>
        <Typography variant='h6' className={classes.title}>
          {props.title}
        </Typography>
        <IconButton edge='start' color='inherit' onClick={props.handleClose} aria-label='close'>
          <CloseIcon />
        </IconButton>
      </Toolbar>
      {/* <DialogTitle id='responsive-dialog-title'>{props.title}</DialogTitle> */}
      <DialogContent>{props.dialogContent}</DialogContent>
      {props.dialogActions !== '' || props.dialogActions ? <DialogActions>{props.dialogActions}</DialogActions> : ''}
    </Dialog>
  );
};

export default CustomDialog;
