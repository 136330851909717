import axios from 'axios';

const options = {
  mode: 'no-cors',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
};

export const callApi = (url, type, payload) => {
  if (payload) return axios[type](url, payload, options);

  return axios[type](url, options);
};

export const getAngleDegFromPercent = percentage => {
  let angle = percentage;
  if (percentage > 0 && percentage <= 34) angle = percentage + 26;
  else if (percentage > 34 && percentage <= 68) angle = percentage + 52;
  else if (percentage > 68 && percentage <= 100) angle = percentage + 80;
  else angle = -1;

  return angle;
};

export const scrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

export const hideMobileKeyboard = () => {
  var field = document.createElement('input');
  field.setAttribute('type', 'text');
  document.body.appendChild(field);

  setTimeout(function() {
    field.focus();
    setTimeout(function() {
      field.setAttribute('style', 'display:none;');
      scrollToTop();
    }, 50);
  }, 50);
};
