import React from 'react';

const Barometer = props => {
  return (
    <div className='layout-align'>
      <div className='score-meter-1'>
        <div className='scorer-1-inner-div'>
          <div className='scorer-1-inner-div-5'>
            <div className='scorer-1-tick' style={{ transform: `rotate(${props.rotateAngle}deg)` }}></div>
          </div>
        </div>
        <div className='scorer-1-inner-div-2'></div>
        <div className='scorer-1-inner-div-3'></div>
        <div className='scorer-1-inner-div-4'></div>
      </div>
    </div>
  );
};

export default Barometer;
