/*
You can use this file to update the text on first page
*/

import React, { useState, useEffect } from 'react';
import { Button, IconButton } from '@material-ui/core';
import { debounce } from 'lodash';

import CustomCard from './components/CustomCard';
import TextBox from './components/TextBox';
import Barometer from './components/Barometer';
import { callApi, getAngleDegFromPercent } from './generalMethods';
import CircularIndeterminate from './components/CircularIndeterminate';
import { hideMobileKeyboard } from './generalMethods';

const ReportToday = props => {
  const [loading, setLoading] = useState(false);
  const [pinError, setPinError] = useState(false);
  const [rotateAngle, setRotateAngle] = useState();
  const [loadBarometer, setLoadBarometer] = useState(false);
  const [users, setUsers] = useState();
  const [totalCount, setTotalCount] = useState(0);

  const pincodeHandler = debounce(value => {
    setLoadBarometer(false);
    // if (value.trim === '' || isNaN(value) || value.toString().length !== 6) {
    //   setPinError(true);
    //   setLoading(false);
    //   return;
    // }

    if (value.trim === '' || isNaN(value)) {
      setPinError(true);
      setLoading(false);
      return;
    }
    setLoading(true);
    setLoadBarometer(false);

    setPinError(false);
    getSafeUnsafePercent(value);
    props.getLatLngFromPin(value);
    hideMobileKeyboard();
    return;
  }, 800);

  useEffect(() => {
    getTotalUsers();
  }, []);

  const getTotalUsers = async () => {
    await callApi(`https://yeboyvrgo9.execute-api.ap-south-1.amazonaws.com/beta/pandemos/totalvote`, 'get')
      .then(res => {
        if (res.status === 200) {
          console.log('res is : ', res.data.body);
          if (res.data.body) {
            setUsers(res.data.body);
          }
        }
      })
      .catch(err => {});
  };

  const getSafeUnsafePercent = async pin => {
    await callApi(`https://yeboyvrgo9.execute-api.ap-south-1.amazonaws.com/beta/pandemos/vote?pincode=${pin}`, 'get')
      .then(res => {
        if (res.status === 200) {
          let angle = -1;
          if (!isNaN(res.data.percentSafe)) {
            let percentage = Number(res.data.percentSafe.slice(0, 5));
            angle = getAngleDegFromPercent(percentage);
            setTotalCount(res.data.totalCount);
          }
          console.log('angle is : ', angle);
          setRotateAngle(angle);
          setLoadBarometer(true);
        }
      })
      .catch(err => {
        setRotateAngle(-1);
      })
      .then(() => {
        setLoading(false);
      });
  };
  return (
    <div className={'reportTodayDialogCon'}>
      <CustomCard className={'firstPageCard'} style={{ boxShadow: 'black 0 0 10px' }}>
        Welcome to the beta Is It Safe?
        <br /> app by Pandemos. <br />
        <br />
        This app uses the collective power of crowd ratings from yourself and others to determine if locations is SAFE.
        With a simple swipe, record your opinion of the relative safety in terms of common viral transmission risk
        factors like:
        <br />
        <ul style={{ textAlign: 'justify' }}>
          <li>If people are wearing masks</li>
          <li>If social distancing is encouraged</li>
          <li>If hand sanitizer is available</li>
        </ul>
        <br />
        <span>
          Take 1 min each day and help fight the outbreak in your community
          {users ? (
            <div>
              <span>{users}</span> <span style={{ color: 'lightgray' }}> people contributing</span>
            </div>
          ) : (
            'Loading ...'
          )}
        </span>
      </CustomCard>
      <TextBox
        label={'Enter Zip code'}
        id={'pincode'}
        onChange={e => {
          e.stopPropagation();
          pincodeHandler(e.target.value);
        }}
        error={pinError}
        helperText={pinError ? 'Invalid Zip code' : ''}
      />

      {loading && <CircularIndeterminate />}
      {loadBarometer ? (
        <div className={'barometerContainer'}>
          <span style={{ fontSize: '17px' }}>{totalCount} Users are using this app in this area.</span>
          <Barometer rotateAngle={rotateAngle} />
          <div style={{ padding: '10%' }}>
            Let’s fight this Pandemic as a community together and provide an early warning system
          </div>
          <Button variant='contained' onClick={props.loadMaps} className={'reportTodayButton'}>
            Report Today
          </Button>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ReportToday;
