import React, { useState } from 'react';

import Header from './components/Header';
import GMaps from './gMaps';
import ReportToday from './ReportToday';

const mapContainerStyle = {
  width: '100vw',
  height: '100vh',
};

export default function Maps(props) {
  const [open, setOpen] = useState(true);
  const [reportToday, setReportToday] = useState(true);
  const [zipCode, setZipCode] = useState();

  // const getLatLngFromPin = async value => {
  //   if (!value) return;
  //   try {
  //     const results = await getGeocode({ address: value });
  //     const { lat, lng } = await getLatLng(results[0]);

  //     console.log("latlng : ", lat, lng);
  //   } catch (err) {
  //     alert(err);
  //     console.log('error', err);
  //   }
  // };

  const getLatLngFromPin = value => {
    setZipCode(value);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const loadMaps = () => {
    setReportToday(false);
  };

  const goToHome = () => {
    setReportToday(true)
  }

  return (
    <div style={mapContainerStyle}>
      <section>
        <Header onClick={goToHome}>IS IT SAFE?</Header>
      </section>
      <section style={{ height: `100%`, width: `100%` }}>
        {reportToday ? <ReportToday open={open} handleClose={handleClose} loadMaps={loadMaps} getLatLngFromPin={getLatLngFromPin} /> : <GMaps zipCode={zipCode} />}
      </section>
    </div>
  );
}
