import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
  root: {
    minWidth: 175,
  },
});

const CustomCard = props => {
  const classes = useStyles();

  return (
    <Card className={`classes.root ${props.className ? props.className : ''}`} {...props}>
      <CardContent>{props.children}</CardContent>
    </Card>
  );
};

export default CustomCard;
