import React, { useState, useRef, useEffect } from 'react';
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from 'react-google-maps';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

import { getGeocode, getLatLng, getZipCode, getDetails } from 'use-places-autocomplete';
import SearchBar from './components/SearchBar';
import MarkerDialog from './MarkerDialog';
import CircularIndeterminate from './components/CircularIndeterminate';
import { callApi, scrollToTop } from './generalMethods';
import { stringContants } from '../constants/ProjectConstants';

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};
const apiKey = stringContants.apikey;

function Map(props) {
  const { zipCode } = props;

  const [current, setCurrentMarkers] = useState();
  const [currentZoom, setCurrentZoom] = useState(10);
  const [mainAddressText, setMainAddressText] = useState();
  const [secondaryAddressText, setSecondaryAddressText] = useState();
  const [placeId, setPlaceId] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pinCode, setPincode] = useState();
  const [isSaveDone, setIsSaveDone] = useState(false);
  const [totalPercent, setTotalPercent] = useState(-1);
  const [initCenter, setInitCenter] = useState({ lat: 12.971599, lng: 77.594566 });
  const [openAlert, setOpenAlert] = useState(true);

  useEffect(() => {
    getLatLngFromZip();
    scrollToTop();
  }, [zipCode]);

  const openButtonClicked = () => {
    setIsSaveDone(false);
    setLoading(true);
    getPercentage();
  };

  const getLatLngFromZip = async () => {
    console.log('zipCode : ', zipCode);
    const results = await getGeocode({ address: zipCode });
    console.log('results : ', results);
    const { lat, lng } = await getLatLng(results[0]);
    console.log('latlng : ', lat, lng);

    setInitCenter({ lat, lng });
  };

  const handleOpen = () => {
    setOpen(true);
  };
  // const handleOpen = () => { setSelected(true) }
  const handleClose = () => {
    setOpen(false);
  };

  const mapRef = useRef();

  const positionMarker = (lat, lng) => {
    setCurrentMarkers({ lat, lng });
    setCurrentZoom(15);
  };

  const onSelect = async value => {
    if (!value) return;
    try {
      let address = value.description;
      const results = await getGeocode({ address });
      let zipCode = await getZipCode(results[0]);
      const { lat, lng } = await getLatLng(results[0]);
      await getDetails({ placeId: value.place_id })
        .then(details => {
          console.log('deatils ', details);
          zipCode = details.address_components.filter(detail => detail.types.includes('postal_code'))[0].long_name;
        })
        .catch(error => {
          console.log('Error getting Details: ', error);
        });
      if (zipCode == null) {
      }
      console.log('zipcode ', zipCode);
      setPincode(zipCode);
      setMainAddressText(value.structured_formatting.main_text);
      setSecondaryAddressText(value.structured_formatting.secondary_text);
      positionMarker(lat, lng);
      setPlaceId(value.place_id);
    } catch (err) {
      alert(err);
      console.log('error', err);
    }
  };

  const getPercentage = async () => {
    await callApi(
      `https://yeboyvrgo9.execute-api.ap-south-1.amazonaws.com/beta/pandemos/vote?placeid=${placeId}`,
      'get',
    )
      .then(res => {
        if (res.status === 200) setTotalPercent(res.data.percentSafe);
      })
      .catch(err => {
        console.log('err', err);
        setTotalPercent(-1);
      })
      .then(() => {
        setLoading(false);
      });

    handleOpen();
  };

  const postSafeUnsafe = async isSafe => {
    handleClose();
    setLoading(true);

    let payload = {
      place_id: placeId.toString(),
      pincode: pinCode,
    };
    if (isSafe) payload.is_safe = 'YES';
    else payload.is_unsafe = 'YES';

    await callApi(`https://yeboyvrgo9.execute-api.ap-south-1.amazonaws.com/beta/pandemos/vote`, 'post', payload)
      .then(res => {
        console.log('responded : ', res);
        setIsSaveDone(true);
        handleOpen();
        setLoading(false);
      })
      .catch(err => {
        console.log('err', err);
        setLoading(false);
      });
  };

  return (
    <GoogleMap
      zoom={currentZoom}
      options={options}
      // onClick={event => {
      //     setCurrentMarkers({
      //         lat: event.latLng.lat(),
      //         lng: event.latLng.lng(),
      //     })
      // }}
      center={current ? current : initCenter}
      ref={mapRef}
      libraries
    >
      <Marker position={current} onClick={openButtonClicked} />
      <SearchBar className={`search`} onLocationSelect={onSelect} />
      <MarkerDialog
        title={mainAddressText || 'Select a place'}
        secondaryAddress={secondaryAddressText}
        openModal={open}
        handleClose={handleClose}
        isSaveDone={isSaveDone}
        totalPercent={totalPercent}
        postSafeUnsafe={postSafeUnsafe}
      />
      {loading && <CircularIndeterminate />}
      <Collapse in={openAlert} className={'collapseAlert'}>
        <Alert
          severity='info'
          onClose={() => {
            setOpenAlert(false);
          }}
        >
          To start, please type in the address of the location that you would like to rate in the “Rate a location”
          field. (Shortcut: Click on the location on the map and copy/paste the address)
        </Alert>
      </Collapse>
    </GoogleMap>
  );
}

const MapContainer = withScriptjs(withGoogleMap(Map));

const GMaps = props => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    options.zoomControl = false;
  }
  return (
    <MapContainer
      googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${apiKey}`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100%` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      zipCode={props.zipCode}
    />
  );
};

export default GMaps;
