import React from 'react';

import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import logo from '../../logo.png';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const SwiperButton = props => {
  return (
    <SwipeableList className={'noSelect'}>
      <SwipeableListItem
        swipeLeft={{
          content: <div style={{ marginRight: '20px', color: 'white' }}>Unsafe</div>,
          action: () => props.onChange(false) || console.log('unsafe'),
        }}
        swipeRight={{
          content: <div style={{ marginLeft: '20px', color: 'white' }}>Safe</div>,
          action: () => props.onChange(true) || console.log('safe'),
        }}
        onSwipeProgress={progress => console.info(`Swipe progress: ${progress}%`)}
      >
        <div className={'swipeMainItem'}>
          {/* <span className='unSafeArrow noSelect'>&#8592;</span> */}
          <ArrowBackIcon className='unSafeArrow noSelect'/>
          <img src={logo} className={'swiperImage noSelect'} />
          <ArrowForwardIcon className='safeArrow noSelect'/>
          {/* <span className='safeArrow noSelect'>&#8594;</span> */}
        </div>

        {/* <div >{'<<< swipe >>>'}</div> */}
      </SwipeableListItem>
    </SwipeableList>
  );
};

export default SwiperButton;
