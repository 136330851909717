/*
You can use this file to update the text on click of Marker 
*/

import React, { useState, useEffect } from 'react';
import { IconButton, Button } from '@material-ui/core';

import copy from 'copy-to-clipboard';

import CustomDialog from './components/CustomDialog';
import CustomCard from './components/CustomCard';
import { getAngleDegFromPercent } from './generalMethods';
import Barometer from './components/Barometer';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import SwiperButton from './components/SwiperButton';
import { stringContants } from '../constants/ProjectConstants';
import logo from '../logo.png';

const MarkerDialog = props => {
  const { openModal, handleClose, totalPercent, isSaveDone, postSafeUnsafe, title, secondaryAddress } = props;
  const [copyDialogOpen, setCopyDialogOpen] = useState(false);

  const [rotateAngle, setRotateAngle] = useState(-1);
  const msg = stringContants.msg;
  useEffect(() => {
    if (!isNaN(totalPercent)) {
      let percentage = Number(totalPercent.toString().slice(0, 5));
      setRotateAngle(getAngleDegFromPercent(percentage));
    } else {
      setRotateAngle(-1);
    }
    // setSliderValue(20);
  }, [totalPercent]);

  const sliderChangeHandler = isSafe => {
    postSafeUnsafe(isSafe);
  };

  const copyToClipboard = () => {
    setCopyDialogOpen(true);
    copy(msg);
  };

  const shareOnFb = () => {
    window.location.href = `https://www.facebook.com/sharer/sharer.php?u=www.isitsafe.me`;
  };

  //isSaveDone : true  then we are opening Sharing is caring Modal
  //else the same Safe and Unsafe dialog.
  const dialogContent = isSaveDone ? (
    <div>
      <div className={'dialogSection'} style={{ textAlign: 'center' }}>
        <img src={logo} style={{ height: '80px', width: '80px' }} />
      </div>
      <div className={'dialogSection'}>
        <h2 style={{ textAlign: 'center' }}>Sharing is caring</h2>
        <p>{'The more people report, the better out estimate & the faster you can help your community fight COVID'}</p>
      </div>
      <div className={'dialogSection'}>
        <CustomCard style={{ background: '#dfe6e9' }}>
          {'Lets fight this Pandemic together and prevent the next one to come.'}
        </CustomCard>
      </div>
      <div className={'dialogSection'}>Share with your friends : </div>
      <div className={'dialogSection shareButtons'}>
        <IconButton variant='contained' style={{ color: '#01E676' }} href={`https://wa.me/?text=${msg}`}>
          <WhatsAppIcon fontSize='large' />
        </IconButton>
        <IconButton variant='contained' color='primary' onClick={copyToClipboard}>
          <FacebookIcon fontSize='large' />
        </IconButton>
      </div>
    </div>
  ) : (
    <div>
      <div className={'dialogSection'}>
        <span>{secondaryAddress}</span>
      </div>
      <div className={'dialogSection'}>
        <CustomCard className={'firstPageCard'}>
          <span>
            Select whether you feel this location is safe based on common risk factors by sliding the icon to the Right
            (if you feel it’s safe) or to the Left (if you don’t feel safe).
          </span>
          <br />
          <br />
          {totalPercent === -1 || totalPercent === '-1'
            ? "0% People reported for this place. Why don't you be the first one !"
            : totalPercent.toString().slice(0, 5) + '% People reported this place as safe'}
        </CustomCard>
        <div className={'barometerContainer'}>
          <Barometer rotateAngle={rotateAngle} />
        </div>
      </div>
      <div className={'dialogSection'}>
        <CustomCard className={'firstPageCard'}>
          <span>Is it safe ?</span>
          <ul style={{ textAlign: 'justify' }}>
            <li>Do you feel if people are wearing masks</li>
            <li>Do you feel if social distancing is encouraged</li>
            <li>Do you feel if good hygiene is demonstrated</li>
          </ul>
        </CustomCard>
      </div>
    </div>
  );

  //isSaveDone : true  then we are opening Sharing is caring Modal Which has only close button
  //else the same Safe and Unsafe dialog.
  const dialogActions = isSaveDone ? (
    ''
  ) : (
    <div style={{ width: '100%', padding: '10px' }}>
      <SwiperButton onChange={sliderChangeHandler} />
    </div>
  );

  const copyDialogAction = (
    <div>
      <Button onClick={shareOnFb} color='primary'>
        Share
      </Button>
    </div>
  );

  const copyDialogContent = <div>The message to spread is copied to your clipboard, You can try sharing it.</div>;

  const copyDialogClose = () => {
    setCopyDialogOpen(false);
  };

  return (
    <div>
      <CustomDialog
        dialogActions={dialogActions}
        handleClose={handleClose}
        open={openModal}
        dialogContent={dialogContent}
        title={isSaveDone ? '' : title}
      />

      <CustomDialog
        dialogActions={copyDialogAction}
        handleClose={copyDialogClose}
        open={copyDialogOpen}
        dialogContent={copyDialogContent}
        title={'Copied to clipboard!'}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        onBackdropClick={() => console.log('closed')}
      />
    </div>
  );
};

export default MarkerDialog;
