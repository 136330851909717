import React from 'react';
import logo from '../../logo.png';

const Header = props => {
  const headerOnClick = () => {
    console.log("no header click handler");
  }
  return (
    <div className={`header`} onClick={props.onClick || headerOnClick}>
      <img src={logo} style={{width: '60px', height: '60px'}} alt={''} />
      <span>{props.children}</span>
    </div>
  );
};

export default Header;
