import React from 'react';
import Maps from './js/index';

function App() {
  return (
    <div>
      <Maps />
    </div>
  );
}

export default App;
